import { API_URL } from '../utils/constants';
import fetchWithTimeout from './fetchWithTimeout';

/**
 * Connexion utilisateur.
 * Si le 2FA est activé, l'API retourne `require2FA: true`.
 * @param {Object} data { username, password }
 * @returns {Object} - { user, auth-token } OU { require2FA, userId }
 */
export async function login(data) {
  const response = await fetchWithTimeout(`${API_URL}users/login/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  });
  return response;
}

/**
 * Vérifie le code 2FA lors de la connexion.
 * @param {Object} data { userId, token } - L'ID utilisateur et le code 2FA.
 * @returns {Object} - { user, auth-token } OU { error }
 */
export async function verify2FA(data) {
  const response = await fetchWithTimeout(`${API_URL}users/login-2fa/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  });
  return response;
}

/**
 * Active la double authentification (2FA) et fournit un QR code.
 * @param {String} token - Token d'authentification de l'utilisateur.
 * @returns {Object} - { qrCode, secret } (QR code à scanner)
 */
export async function enable2FA(token, forceRegenerate) {
  const response = await fetchWithTimeout(`${API_URL}users/enable-2fa/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'auth-token': token, // Assurez-vous que le token est bien envoyé
    },
    body: JSON.stringify({
      forceRegenerate: forceRegenerate, // Passer forceRegenerate dans le body
    }),
  });
  return response;
}

/**
 * Vérifie le code 2FA après activation pour l'enregistrer définitivement.
 * @param {Object} data { token } - Le code 2FA à valider.
 * @param {String} authToken - Token de l'utilisateur.
 * @returns {Object} - { message } en cas de succès.
 */
export async function verify2FASetup(data, authToken) {
  const response = await fetchWithTimeout(`${API_URL}users/verify-2fa/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json', 'auth-token': authToken },
  });
  return response;
}

/**
 * Désactive la double authentification (2FA).
 * @param {String} token - Token d'authentification de l'utilisateur.
 * @returns {Object} - { message } en cas de succès.
 */
export async function disable2FA(token) {
  const response = await fetchWithTimeout(`${API_URL}users/disable-2fa/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
  return response;
}

/**
 * Récupère la liste des utilisateurs.
 * @param {String} token - Token de l'utilisateur connecté.
 * @param {Object} params - { offset, limit, username, status }.
 * @returns {Array} - Liste des utilisateurs.
 */
export async function getUsers(
  token,
  { offset = 0, limit = 20, username = '', status = [] }
) {
  const users = await fetchWithTimeout(
    `${API_URL}users/?limit=${limit}&offset=${offset}${
      username.length > 0 ? `&username=${username}` : ''
    }${status.length > 0 ? `&status=${status.join('&status=')}` : ''}`,
    {
      headers: { 'Content-Type': 'application/json', 'auth-token': token },
    }
  );
  return users;
}

/**
 * Récupère un utilisateur spécifique.
 * @param {String} username - Le nom d'utilisateur.
 * @param {String} token - Token de l'utilisateur connecté.
 * @returns {Object} - Informations de l'utilisateur.
 */
export async function getUser(username, token) {
  const user = await fetchWithTimeout(`${API_URL}users/${username}`, {
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
  return user;
}

/**
 * Crée un nouvel utilisateur.
 * @param {Object} body - Données du nouvel utilisateur.
 * @param {String} token - Token d'authentification de l'admin.
 * @returns {Object} - Utilisateur créé.
 */
export async function createUser(body, token) {
  const post = await fetchWithTimeout(`${API_URL}users/`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
  return post;
}

/**
 * Modifie un utilisateur existant.
 * @param {String} username - Le nom d'utilisateur à modifier.
 * @param {Object} body - Nouvelles informations.
 * @param {String} token - Token d'authentification.
 * @returns {Object} - Utilisateur mis à jour.
 */
export async function editUser(username, body, token) {
  const patch = await fetchWithTimeout(`${API_URL}users/${username}`, {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
  return patch;
}

/**
 * Supprime un utilisateur.
 * @param {String} username - Le nom d'utilisateur à supprimer.
 * @param {String} token - Token d'authentification.
 * @returns {Object} - Réponse de suppression.
 */
export async function deleteUser(username, token) {
  const user = await fetchWithTimeout(`${API_URL}users/${username}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'auth-token': token },
  });
  return user;
}
